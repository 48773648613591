import React, { Component } from 'react'

const female_non_smokers = [
    //age 0 - 40, 5 - 70
        //SAD
        [
            //OHS
            [
                0,0,0,0
            ],[
                0,0,0,0
            ],[
                0,0,0,0
            ],[
                0,0,1,1
            ]
        ],[
            [
                0,0,0,0
            ],[
                0,0,1,1
            ],[
                1,1,1,1
            ],[
                1,1,2,2
            ]
        ],[
            [
                1,1,1,1
            ],[
                1,1,1,1
            ],[
                1,2,2,2
            ],[
                2,2,3,3
            ]
        ],[
            [
                1,1,2,2
            ],[
                2,2,2,3
            ],[ 
                3,3,3,4
            ],[
                4,4,5,5
            ]
        ],[
           [
               3,3,4,4
           ],[
               4,4,5,5
           ],[
               5,6,6,7
           ],[
               7,8,8,9
           ]
        ],[
            [
                7,7,8,9
            ],[
                8,9,10,10
            ],[
                10,11,12,13
            ],[
                12,13,14,15
            ]
        ]
];

const male_non_smokers = [
    //age 0 - 40, 5 - 70
        //SAD
        [
            //OHS
            [
                0,0,0,1
            ],[
                0,1,1,1
            ],[
                1,1,1,2
            ],[
                2,2,2,3
            ]
        ],[
            [
                1,1,1,2
            ],[
                2,2,2,3
            ],[
                2,3,3,4
            ],[
                4,5,6,7
            ]
        ],[
            [
                2,2,3,3
            ],[
                3,3,4,5
            ],[
                4,5,6,7
            ],[
                6,7,9,10
            ]
        ],[
            [
                4,4,5,6
            ],[
                5,6,7,8
            ],[ 
                7,8,10,11
            ],[
                10,11,13,15
            ]
        ],[
           [
               7,8,10,11
           ],[
               9,11,12,14
           ],[
               12,14,16,18
           ],[
               15,17,20,23
           ]
        ],[
            [
                13,15,17,20
            ],[
                16,18,21,24
            ],[
                20,22,25,28
            ],[
                24,26,30,33
            ]
        ]
];

const female_smokers = [
    //age 0 - 40, 5 - 70
    [
        //OHS
        [
            0,0,0,0
        ],[
            0,0,0,1
        ],[
            1,1,1,1
        ],[
            1,1,2,2
        ]
    ],[
        [
            1,1,1,1
        ],[
            1,1,1,2
        ],[
            2,2,2,3
        ],[
            3,3,4,4
        ]
    ],[
        [
            1,1,2,2
        ],[
            2,2,2,3
        ],[
            3,3,4,4
        ],[
            5,5,6,7
        ]
    ],[
        [
            3,3,3,3
        ],[
            4,4,4,5
        ],[ 
            5,6,6,7
        ],[
            7,8,9,10
        ]
    ],[
       [
           5,5,6,7
       ],[
           7,7,8,9
       ],[
           9,9,10,11
       ],[
           11,12,13,15
       ]
    ],[
        [
            10,10,11,12
        ],[
            12,13,14,15
        ],[
            14,15,16,18
        ],[
            17,19,20,21
        ]
    ]
];

const male_smokers = [
    //age 0 - 40, 5 - 70
    [
        //OHS
        [
            1,1,1,1
        ],[
            1,1,2,2
        ],[
            2,2,3,4
        ],[
            4,4,5,7
        ]
    ],[
        [
            2,2,3,4
        ],[
            3,4,5,6
        ],[
            5,6,7,9
        ],[
            8,9,11,13
        ]
    ],[
        [
            4,4,5,6
        ],[
            6,6,7,9
        ],[
            8,9,11,13
        ],[
            11,13,16,18
        ]
    ],[
        [
            6,7,9,10
        ],[
            9,10,12,14
        ],[ 
            12,14,16,19
        ],[
            16,19,22,25
        ]
    ],[
       [
          11,13,15,17
       ],[
           14,16,19,22
       ],[
           18,21,24,27
       ],[
           23,26,30,34
       ]
    ],[
        [
            19,22,25,28
        ],[
            23,26,29,33
        ],[
            27,31,34,39
        ],[
            33,36,40,45
        ]
    ]
];

export default class MatrixSolver extends Component {

    constructor(props){
        super(props)
        this.state = {
            age:'40',
            gender:'0',
            isSmoking:"1",
            SAD:'100',
            OHS:'3',
            currentLanguage: sessionStorage.getItem('lang')
        }
    }
     convert = (txt) => 
   {
    var i, tt="";
    for (i=0;i<txt.length;i++)
    {
     if (txt.charAt(i)===",") tt=tt+".";
     else tt=tt+txt.charAt(i); 
    }
    return tt;
   }
   
    DoIt = (gen,age,ad,smoke,chol) => {
        console.log('Do it ')

        var alpha,p,cs0,cs10,ncs0,ncs10,wc,wnc;
        var bchol,bsbp,bsm,cs,cs1,ncs,ncs1,r,r1;
        age=this.convert(age);
        ad=this.convert(ad);
        chol=this.convert(chol);
        if (gen===0)
        {
        alpha=-21.0;p=4.62;  
        }
        else
        {
        alpha=-28.7;p=6.23;
        }
        cs0=Math.exp(-Math.exp(alpha)*Math.pow(age-20.0,p));
        cs10=Math.exp(-Math.exp(alpha)*Math.pow(age-10.0,p));
        
        if (gen===0)
        {
        alpha=-25.7;p=5.47;
        }
        else
        {
        alpha=-30.0;p=6.42;
        }
        
        
        ncs0=Math.exp(-Math.exp(alpha)*Math.pow(age-20.0,p));
        
        ncs10=Math.exp(-Math.exp(alpha)*Math.pow(age-10.0,p));
        
        bchol=0.24;
        bsbp=0.018;
        bsm=smoke*0.71;
        wc=bchol*(chol-6.0)+bsbp*(ad-120.0)+bsm;
        
    
        bchol=0.02;
        bsbp=0.022;
        bsm=smoke*0.63;
        wnc=bchol*(chol-6.0)+bsbp*(ad-120.0)+bsm;
        
    
        cs=Math.pow(cs0,Math.exp(wc));
        
        cs1=Math.pow(cs10,Math.exp(wc));
        
        ncs=Math.pow(ncs0,Math.exp(wnc));
        
        ncs1=Math.pow(ncs10,Math.exp(wnc));
        
        cs1=cs1/cs;
        ncs1=ncs1/ncs;
    
        r=1.0-cs1;
        r1=1.0-ncs1; 
        let result = (100.0*(r+r1));
        console.log('calculation ' + result);
        return this.indexSolver(result)
   }
   
   
    onChangegender = (event) => {
        this.setState({
            gender: event.target.value
        })
    }
    onChangeAge = (event) => {
        this.setState({
            age: event.target.value
        })
    }
    onChangeSmoking = (event) => {
        this.setState({
            isSmoking: event.target.value
        })
    }
    onChangeSAD = (event) => {
        this.setState({
            SAD: event.target.value
        })
    }
    onChangeOHS = (event) =>{
        this.setState({
            OHS: event.target.value
        })
    }

    submit = (e) => {
        e.preventDefault()
        let answers = this.state
        console.log(answers)
        let { gender, age, isSmoking, SAD, OHS } = answers
        if(SAD > 180){
            this.props.setid('very-high-risk-2')
            return this.props.setstate('qna')
        }
        if(OHS >= 8){
            this.props.setid('very-high-risk-2')
            return this.props.setstate('qna')
        }
        if(40 <= parseInt(age) && parseInt(age) <= 70 && 120 <= parseInt(SAD)  && parseInt(SAD) <= 180 && 4 <= parseInt(OHS)  && parseInt(OHS) <= 7){
            return this.matrix(gender,age,SAD,OHS,isSmoking)
        }

        return this.DoIt(gender,age,SAD,isSmoking,OHS)
    }

    

    matrix = (gender, age, sad, ohs, isSmoking) => {

        let atu = []
        if(gender === '0'){
            if(isSmoking === '1'){
                atu = male_smokers
            }
            if(isSmoking === '0'){
                atu = male_non_smokers
            }
        }
        if(gender === '1'){
            if(isSmoking === '1'){
                atu = female_smokers
            }
            if(isSmoking === '0'){
                atu = female_non_smokers
            }
        }


        let v_index = this.getSadIndex(sad)
        let h_index = this.getOhsIndex(ohs)
        let age_index = this.getAgeIndex(age)

        let result = (atu[age_index][v_index][h_index])
        console.log('matrix solved')
        return this.indexSolver(result)
    }
    getAgeIndex = (age) => {
        age = parseInt(age)
        if(age >= 40 && age <= 49){
            return 0
        }
        if(age >= 50 && age <= 54){
            return 1
        }
        if(age >= 55 && age <= 59){
            return 2
        }
        if(age >= 60 && age <= 64){
            return 3
        }
        if(age >= 65 && age < 70){
            return 4
        }
        if(age >= 70){
            return 5
        }
        return new Error('wrong age')
    }

    getSadIndex = (sad) => {
        sad = parseInt(sad)
        if(sad >= 120 && sad <= 129){
            return 0
        }
        if(sad >= 130 && sad <= 149){   
            return 1
        }
        if(sad >= 150 && sad <= 169){
            return 2
        }
        if(sad >= 170 && sad <= 180){
            return 3
        }
        return new Error('wrong sad')
    }
    getOhsIndex = (ohs) => {
        ohs = parseInt(ohs)
        return ohs - 4 
    }


    

    indexSolver = (index) => {
        console.log('Index: '+index)

        if(index <= 2){

            this.props.setid('low-risk-2')
            return this.props.setstate('qna') 
            
        }
        if(index <= 4){
            this.props.setid('moderate-risk-2')
            return this.props.setstate('qna')
        }
        if(index <= 9){

            this.props.setid('high-risk-2')
            return this.props.setstate('qna')
        }
        if(index > 9){

            this.props.setid('very-high-risk-2')
            return this.props.setstate('qna')
        }
    }

    render() {
        return (
            <div className="container">
            <div className="card" id="content">
                <img src="/RKO.png" alt='logo'></img>
                <div className='matrix_solver_container'>
                <form onSubmit={this.submit}>
                    <p>{this.state.currentLanguage === 'ru' ? 'Пол пациента ' : 'Patient gender'}</p>
                    <div className='matrix_input' onChange={this.onChangegender}>
                        <input type="radio" id="gender_male"
                        name="gender" value="0" defaultChecked></input>
                        <label htmlFor="gender_male">{this.state.currentLanguage === 'ru' ? 'Мужчина' : 'Male'}</label>
                        <input type="radio" id="gender_female"
                        name="gender" value="1"></input>
                        <label htmlFor="gender_female">{this.state.currentLanguage === 'ru' ? 'Женщина' : 'Female'}</label>
                    </div>
                    <p>{this.state.currentLanguage === 'ru' ? 'Возраст' : 'Age'} {this.state.age}</p>
                    <input type="range" id="vol" name="ohs" min="40" max="89" step='1' value={this.state.age} onChange={this.onChangeAge}></input>
                    <p>{this.state.currentLanguage === 'ru' ? 'Курение' : 'Smoking'} </p>
                    <div className='matrix_input' onChange={this.onChangeSmoking}>
                        <input type="radio" id="smoking_1"
                        name="smoking" value="1" defaultChecked></input>
                        <label htmlFor="smoking_1">{this.state.currentLanguage === 'ru' ? 'Да' : 'Yes'}</label>
                        <input type="radio" id="smoking_0"
                        name="smoking" value="0"></input>
                        <label htmlFor="smoking_0">{this.state.currentLanguage === 'ru' ? 'Нет' : 'No'}</label>
                    </div>
                    <p>{this.state.currentLanguage === 'ru' ? 'САД' : 'Systolic blood pressure'} {this.state.SAD}</p>
                    
                    <input type="range" id="vol" name="SAD" min="100" max="179" step='1' value={this.state.SAD} onChange={this.onChangeSAD}></input>

                    <p>{this.state.currentLanguage === 'ru' ? 'ОХС' : 'Total cholesterol'} {this.state.OHS}</p>
                    <div className='matrix_input' onChange={this.onChangeOHS}>
                        <input type="range" id="vol" name="ohs" min="3.0" max="6.9" step='0.1' value={this.state.OHS} onChange={this.onChangeOHS}></input>
                    </div>
                </form>
                </div>
                <div className="answers mt-40">
                    <button className="answer" onClick={this.submit}>
                    {this.state.currentLanguage === 'ru' ? 'Продолжить' : 'Continue'} 
                    </button>
                    </div>
            </div>
            </div>
        )
    }
}


// 