import React, { Component } from 'react';

const axios = require('axios');
export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_id: 'start',
      parent_id: '',
      card_name: '',
      card_text: '',
      show: 'none',
      answer_name: '',
      card_answers: [],
      modules: [],
    };
  }
  componentDidMount() {
    this.loadCard('start');
  }
  loadCard = async (id) => {
    let page = document.getElementById('admin');
    page.style.opacity = 0;
    let request = await axios.post('https://www.lipids.scardio.ru/api/admin/question/', { id: id });
    let { post, modules } = request.data;
    if (post.answers.length < 1) {
      this.setState({
        current_id: post.id,
        card_type: post.type,
        module_id: post.module_id,
        modules: modules,
        card_text: post.question,
        card_name: post.name,
        card_answers: post.answers,
        parent_id: post.parent_id,
      });
      return (page.style.opacity = 1);
    }
    this.setState({
      current_id: post.id,
      card_type: post.type,
      module_id: post.module_id,
      modules: modules,
      card_text: post.question,
      card_name: post.name,
      card_answers: post.answers.answers,
      parent_id: post.parent_id,
    });
    page.style.opacity = 1;
  };
  saveCard = async (e) => {
    e.preventDefault();
    let request = await axios.post('https://www.lipids.scardio.ru/api/question/update', {
      id: this.state.current_id,
      text: this.state.card_text,
      name: this.state.card_name,
      type: this.state.card_type,
      module_id: this.state.module_id,
    });

    console.log(request);
    alert('Успешно');
  };
  deleteCard = async (e) => {
    e.preventDefault();
    if (this.state.current_id === 'start') {
      return window.confirm('Невозможно удалить вступительную карточку');
    }
    let request = await axios.post('https://www.lipids.scardio.ru/api/question/delete', {
      id: this.state.current_id,
      parent_id: this.state.parent_id,
    });
    if (request.data.status === 'OK') {
      alert('Карточка успешно удалена');
      this.loadCard(this.state.parent_id);
    }
  };
  loadParent = (e) => {
    e.preventDefault();
    if (this.state.current_id === 'start') {
      return window.confirm('Вступительная карточка не может быть дочерней');
    }
    this.loadCard(this.state.parent_id);
    console.log(this.state.parent_id);
  };
  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleClose = () => {
    this.setState({
      show: 'none',
    });
  };
  handleOpen = () => {
    this.setState({
      show: 'block',
    });
  };
  changePage = (e) => {
    e.preventDefault();
    console.log(e.target.id);
    this.setState({
      current_id: e.target.id,
    });
    this.loadCard(e.target.id);
  };
  handleNewAnswer = async (e) => {
    e.preventDefault();
    let name = this.state.answer_name;
    let id = Math.random().toString(36).substr(2, 9);
    console.log('making array');
    let currentAnswers = this.state.card_answers;
    currentAnswers.push({ id, name });
    let aaa = { answers: currentAnswers };

    let response = await axios.post('https://www.lipids.scardio.ru/api/question/make', { parent_id: this.state.current_id, id: id, answers: aaa });
    console.log(response);
    alert('Ответ добавлен');
    this.setState({
      answer_name: '',
    });
    this.handleClose();
  };
  render() {
    return (
      <>
        <div className="admin__wrapper" id="admin">
          <div className="admin__container">
            <div className="center">
              <div className="add--group">
                <h3>Карточка / </h3>
                <button className="group__button" onClick={this.saveCard}>
                  Сохранить
                </button>
                <h3> / </h3>
                <button className="group__button green" onClick={this.loadParent}>
                  К родительской карточке
                </button>
                <h3> / </h3>
                <button className="group__button red" onClick={this.deleteCard}>
                  Удалить
                </button>
              </div>
              <div className="text--hidden">id: {this.state.current_id}</div>
              <p className="input--helper">Название карточки</p>
              <input type="text" value={this.state.card_name} onChange={this.handleChange} name="card_name" className="admin__input"></input>
              <p className="input--helper">Тип карточки</p>
              <select value={this.state.card_type} name="card_type" onChange={this.handleChange} className="admin__input">
                <option value="q">Вопрос</option>
                <option value="r">Результат</option>
              </select>
              <p className="input--helper">Модуль</p>
              <select value={this.state.module_id} name="module_id" onChange={this.handleChange} className="admin__input">
                {this.state.modules.map((object) => {
                  return (
                    <option value={object.id}>
                      {object.id} / {object.eng}
                    </option>
                  );
                })}
              </select>

              <p className="input--helper">Вопрос/Текст</p>
              <textarea
                className="admin__textarea"
                onChange={this.handleChange}
                name="card_text"
                value={this.state.card_text}
                type="text"
                as="textarea"
              />
              <div className="add--group">
                <p className="input--helper">Ответы</p>
                <button className="group__button" onClick={this.handleOpen}>
                  Добавить ответ
                </button>
              </div>
              {this.state.card_answers.map((obj, index) => {
                return (
                  <div className="group__button mt-25" id={obj.id} onClick={this.changePage} key={index}>
                    {obj.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="overlay" style={{ display: this.state.show }}>
          <div className="overlay__wrapper">
            <div className="overlay--content">
              <div className="overlay--container">
                <h3>Добавить ответ</h3>
                <input
                  type="text"
                  name="answer_name"
                  placeholder="Текст кнопки"
                  onChange={this.handleChange}
                  className="admin__input-half"
                  required
                  value={this.state.answer_name}
                />
                <div>
                  <button className="group__button red" onClick={this.handleClose}>
                    Отмена
                  </button>
                  <button className="group__button" onClick={this.handleNewAnswer}>
                    Сохранить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
