import axios from 'axios';
import React, { Component } from 'react';
// const axios = require('axios')
import arrow from './arrow.png';
import rko_logo from './rko.png';
const he = require('he');

export default class NotPermitted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      post_type: '',
      answers: [],
      currentLang: sessionStorage.getItem('lang'),
      prev_id: '',
      parent_id: '',
    };
  }
  reload = (e) => {
    e.preventDefault();
    this.props.dropSession();
  };
  changeLanguage = (e) => {
    e.preventDefault();
    if (this.state.currentLang === 'eng') {
      this.setState({
        currentLang: 'ru',
      });
    } else {
      this.setState({
        currentLang: 'eng',
      });
    }
    this.load(this.state.id);
  };

  load = async (id) => {
    console.log('asking for ' + id);
    let request = await axios.post('https://www.lipids.scardio.ru/api/question/', { id: id });
    if (request === undefined) {
      return console.error('something went wrong');
    }
    let post = await request.data.post;
    let module = request.data.module;
    let text = '';
    console.log(request);
    if (this.state.currentLang === 'ru') {
      text = module.ru;
    }
    if (this.state.currentLang === 'eng') {
      text = module.eng;
    }
    console.log(module);

    if (post === undefined) {
      this.setState({
        id: id,
      });
      console.log('error');
    }

    if (post.answers.length < 1) {
      this.setState({
        id: post.id,
        post_type: post.type,
        question: he.decode(text),
        answers: [],
        parent_id: post.parent_id,
      });
      return (document.getElementById('content').style.opacity = 1);
    }

    this.setState({
      id: post.id,
      post_type: post.type,
      question: he.decode(text),
      answers: post.answers.answers,
      parent_id: post.parent_id,
    });
    document.getElementById('content').style.opacity = 1;
  };
  answerClick = (e) => {
    e.preventDefault();
    let id = e.target.id;
    if (id === '' || id === null || id === undefined) {
      id = e.target.parentNode.id;
      console.log(id);
    }
    let content = document.getElementById('content');
    content.style.opacity = 0;

    this.setState({ prev_id: this.state.id });

    this.load(id);
  };

  getLocalizedAnswer = (object, text) => {
    if (text === 'Да') {
      if (this.state.currentLang === 'eng') {
        return 'Yes';
      }
      return text;
    }
    if (text === 'Нет') {
      if (this.state.currentLang === 'eng') {
        return 'No';
      }
      return text;
    }
    return this.getLocalizedTherapy(object);
  };
  getLocalizedTherapy = (object) => {
    console.log('therapy', object);
    if (this.state.currentLang === 'ru') {
      let ruString = object.ru;
      if (ruString === undefined) {
        return object.name;
      }
      if (ruString.includes('иPCSK9')) {
        ruString = ruString.replace('иPCSK9', 'ингибитор PCSK9');
      }
      return ruString;
    }
    return object.name;
  };
  loadPrev = (event) => {
    event.preventDefault();
    // let prev = this.state.prev_id
    // if(prev === this.state.id){
    return this.load(this.state.parent_id);
    // }
    // this.load(this.state.prev_id);
  };

  render() {
    return (
      <div className="container">
        <div className="card" id="content">
          <div className="d-flex">
            {this.state.prev_id !== '' ? <img src={arrow} alt="back-button" onClick={this.loadPrev} className="icon"></img> : <></>}
            <img src={rko_logo} className="icon-logo" alt="logo"></img>

            <div className="flag" onClick={this.changeLanguage}>
              {this.state.currentLang === 'ru' ? '🇬🇧' : '🇷🇺'}
            </div>
          </div>

          <h3 className="cardQuestion">Медикаментозная терапия не показана</h3>
          <div className="answers">
            <button className="answer" onClick={this.reload}>
              <h3>Вернуться в начало теста</h3>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
