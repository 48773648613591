import './App.css';
import QID from './QID';
import {Route, BrowserRouter, withRouter} from 'react-router-dom'
import Admin from './Pages/Admin'
import Modules from './Pages/Modules'
import Main from './Pages/Main'
export default function App() {

  return (
    <BrowserRouter>
      <Route exact path="/" component={withRouter(Main)}/>
      <Route exact path="/admin" component={withRouter(Admin)}/>
      <Route exact path="/modules" component={withRouter(Modules)}/>
      <Route path="/admin/:id" component={withRouter(QID)}/>
    </BrowserRouter>
  );
}

