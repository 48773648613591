import React, { useState } from 'react';

import Welcome from './Welcome';

import Deaseas from './Deaseas';

import MatrixSolver from './MatrixSolver';
import MatrixSolved2 from './MatrixSolved2';
import Language from './Language';

import QYN2 from './QYN2';
import NotPermitted from './NotPermitted';

export default function Main() {
  //to launch: useState ("language")
  const [page, setPage] = useState('language'); // language
  const [card_id, setCard_id] = useState('');

  //to launch: decomment

  const dropSession = () => {
    setPage('welcome');
    setCard_id('');
  };

  if (page === null || page === undefined || page === '') {
    setPage('language');
  }
  if (page === 'language') {
    return <Language setstate={setPage} />;
  }
  if (page === 'welcome') {
    return <Welcome setstate={setPage} />;
  }
  if (page === 'not-permitted') {
    return <NotPermitted dropSession={dropSession} setstate={setPage} />;
  }
  if (page === 'deaseas') {
    return <Deaseas setid={setCard_id} setstate={setPage} />;
  }
  if (page === 'calculator') {
    return <MatrixSolver setid={setCard_id} setstate={setPage} />;
  }
  if (page === 'calculator-2') {
    return <MatrixSolved2 setid={setCard_id} setstate={setPage} />;
  }
  if (page === 'qna') {
    return <QYN2 dropSession={dropSession} id={card_id} />;
  }
  return <div></div>;
}
