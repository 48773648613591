import React, { Component } from 'react'

const he = require('he')

export default class Module extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: this.props.id,
            ru: this.props.ru,
            eng: this.props.eng
        }
        
    }
    componentDidMount(){

        this.setState({
            ru: he.decode(this.state.ru),
            eng: he.decode(this.state.eng)
        })
    }
    handleChange = (e) => {
        e.preventDefault();

        this.setState({[e.target.name]:e.target.value});

    }
    render() {
        return (
            <div className="module_wrapper">
                <div>{this.state.id}</div>
                <div className="module">
                <div className="m_input_wrap">
                    🇬🇧
                    <textarea name="eng" id={'eng_'+this.state.id} className='module_input' value={this.state.eng} onChange={this.handleChange}></textarea>
                </div>
                <div className="m_input_wrap">
                    🇷🇺
                    <textarea name="ru" id={'ru_'+this.state.id} className='module_input' value={this.state.ru} onChange={this.handleChange}></textarea>
                </div>
                </div>
                <button className='button mt-25' id={this.state.id} onClick={this.props.update}>Сохранить</button>

                <button className='group__button button-red mt-25' id={'_'+this.state.id} onClick={this.props.delete}>Удалить</button>
            </div>
        )
    }
}
