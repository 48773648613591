import React, { Component } from 'react'


const ru = ["Установленное ССЗ атеросклеротического генеза",'Перенесенный ОКС (ИМ, нестабильная стенокардия)','Стабильная стенокардия','Коронарная реваскуляризация (ЧВК, АКШ или другую реваскуляризацию артейрий)', 'Инсульт и ТИА, ЗПА','Стеноз >50%','СД с поражением органов-мишеней, либо наличие как минимум трех основных ФР или длительное течение СД 1 типа с ранним дебютом (>20 лет)','Выраженная ХБП','СГХС при наличии ССЗ атеросклеротического генеза или другое значимое ФР','Значимое повышение какого-либо ФР, в частности, ОХС > 8 ммоль/л, ХС ЛНП > 4.9 ммоль/л или АД >= 180/110 мм рт. ст','СГХС без других значимых ФР','СД без поражения органов-мишеней, при длительности СД >= 10 лет или при наличии другого ФР','Умеренная ХБП','СД 1 типа < 35 лет, СД2 типа < 50 лет с длительностью СД < 10 лет в отсутствие других ФР']
const eng = ["Documented ASCVD, either clinical or unequivocal on imaging", "Documented ASCVD includes previous ACS (MI, or unstable angina)", 'Stable angina', 'Coronary revascularization (PCI, CABG, and other arterial revascularization)', 'Stroke and TIA', 'Stenosis >50%', 'DM with target organ damage, at least three major risk factors, or early onset of T1DM of long duration (>20 years)','Severe CKD', 'FH with ASCVD or another risk factor', 'FH without other major risk factors','Markedly elevated signle risk factors, in particular TC > 8 mmol/L, LDL-C > 4.9 mmol/L, or BP >= 180/110 mmHG', 'DM without target organ damage, with duration >=10 years or another additional risk factor', 'Moderate CKD', 'Young patients (T1DM < 35 years, T2DM < 50 years) with DM duration < 10 years, without other risk factors']

export default class Deaseas extends Component {
    constructor(props){
        super(props)
        this.state = {
            check_1: false,
            check_2: false,
            check_3: false,
            check_4: false,
            check_5: false,
            check_6: false,
            check_7: false,
            check_8: false,
            check_9: false,
            check_10: false,
            check_11: false,
            check_12: false,
            check_13: false,
            check_14: false,

            lang: 'ru',
        }
    }
    componentDidMount(){
        let l  = sessionStorage.getItem('lang')
        if(l === null || l === undefined || l === ''){
            l = 'ru'
            sessionStorage.setItem('lang','ru')
        }
        this.setState({lang: l})
    }
    handleClick = (e) => {
        e.preventDefault()
        this.setState({
            [e.target.name]:e.target.checked
        })
    }
    submit = (e) => {
        e.preventDefault()
        console.log('submit')
        if(this.state.check_1 === true || this.state.check_2 === true || this.state.check_3 === true || this.state.check_4 === true || this.state.check_5 === true || this.state.check_6 === true || this.state.check_7 === true || this.state.check_8 === true || this.state.check_9 === true){
            this.props.setid('very-high-risk-2')
            return this.props.setstate('qna')
        }
        if(this.state.check_10 === true || this.state.check_11 === true || this.state.check_12 === true || this.state.check_13 === true ){
            this.props.setid('high-risk-2')
            return this.props.setstate('qna')
        }
        if(this.state.check_14 === true){
            this.props.setid('moderate-risk-2')
            return this.props.setstate('qna')
        }
        return this.props.setstate('calculator')
    }
    render() {
        let lang = []
        if(this.state.lang === 'ru'){
            lang = ru
        }
        if(this.state.lang === 'eng'){
            lang = eng
        }
        return (
            <div className="container">
            <div className="card" id="content">
                <img src="/RKO.png" alt='logo'></img>

                <div className='matrix_solver_container'>
                    <div className="w90">
                        <p>{ lang === ru ? "Наличие у пациента" : "Patient with any of the following"}</p>
                        <div className="left">
                            <input id="check_1" key={Math.random()} name="check_1" onChange={this.handleClick} type="checkbox" checked={this.state.check_1}></input>
                            <label htmlFor="check_1">{lang[0]}</label>
                        </div>
                        <div className="left">
                            <input id="check_2" key={Math.random()} name="check_2" onChange={this.handleClick} type="checkbox" checked={this.state.check_2}></input>
                            <label htmlFor="check_2">{lang[1]}</label>
                        </div>
                        <div className="left">
                            <input id="check_3" key={Math.random()} name="check_3" onChange={this.handleClick} type="checkbox" checked={this.state.check_3}></input>
                            <label htmlFor="check_3">{lang[2]}</label>
                        </div>
                        <div className="left">
                            <input id="check_4" key={Math.random()} name="check_4" onChange={this.handleClick} type="checkbox" checked={this.state.check_4}></input>
                            <label htmlFor="check_4">{lang[3]}</label>
                        </div>
                        <div className="left">
                            <input id="check_5" key={Math.random()} name="check_5" onChange={this.handleClick} type="checkbox" checked={this.state.check_5}></input>
                            <label htmlFor="check_5">{lang[4]}</label>
                        </div>
                        <div className="left">
                            <input id="check_6" key={Math.random()} name="check_6" onChange={this.handleClick} type="checkbox" checked={this.state.check_6}></input>
                            <label htmlFor="check_6">{lang[5]}</label>
                        </div>
                        <div className="left">
                            <input id="check_7" key={Math.random()} name="check_7" onChange={this.handleClick} type="checkbox" checked={this.state.check_7}></input>
                            <label htmlFor="check_7">{lang[6]}</label>
                        </div>
                        <div className="left">
                            <input id="check_8" key={Math.random()} name="check_8" onChange={this.handleClick} type="checkbox" checked={this.state.check_8}></input>
                            <label htmlFor="check_8">{lang[7]}</label>
                        </div>
                        <div className="left">
                            <input id="check_9" key={Math.random()} name="check_9" onChange={this.handleClick} type="checkbox" checked={this.state.check_9}></input>
                            <label htmlFor="check_9">{lang[8]}</label>
                        </div>
                        <div className="left">
                            <input id="check_10" key={Math.random()} name="check_10" onChange={this.handleClick} type="checkbox" checked={this.state.check_10}></input>
                            <label htmlFor="check_10">{lang[9]}</label>
                        </div>
                        <div className="left">
                            <input id="check_11" key={Math.random()} name="check_11" onChange={this.handleClick} type="checkbox" checked={this.state.check_11}></input>
                            <label htmlFor="check_11">{lang[10]}</label>
                        </div>
                        <div className="left">
                            <input id="check_12" key={Math.random()} name="check_12" onChange={this.handleClick} type="checkbox" checked={this.state.check_12}></input>
                            <label htmlFor="check_12">{lang[11]} </label>
                        </div>
                        <div className="left">
                            <input id="check_13" key={Math.random()} name="check_13" onChange={this.handleClick} type="checkbox" checked={this.state.check_13}></input>
                            <label htmlFor="check_13">{lang[12]}</label>
                        </div>
                        <div className="left">
                            <input id="check_14" key={Math.random()} name="check_14" onChange={this.handleClick} type="checkbox" checked={this.state.check_14}></input>
                            <label htmlFor="check_14">{lang[13]}</label>
                        </div>
                </div>

                </div>
                <div className="answers mt-40">
                    <button className="answer" onClick={this.submit}>
                    { lang === ru ? "Продолжить" : "Continue"}
                    </button>
                    </div>
            </div>
            </div>
        )
    }
}
