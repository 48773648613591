import React from 'react';

export default function Welcome(props) {
  let language = sessionStorage.getItem('lang');
  const string = language === 'ru' ? 'Добро пожаловать' : 'Welcome';
  const string2 = language === 'ru' ? 'Добро пожаловать' : 'Welcome';
  const start = (e) => {
    e.preventDefault();
    console.log(window.location.host);
    const { host } = window.location;
    if (host.includes('2022')) {
      // fix calc-2
      // props.setstate('calculator-2');

      props.setstate('deaseas');
    } else {
      props.setstate('deaseas');
    }
  };

  return (
    <div className="container">
      <div className="card">
        <img src="/RKO.png" alt="logo"></img>
        <h3 className="cardQuestion">{window.location.host.includes('2022') ? string2 : string}</h3>
        <div className="answers">
          <button onClick={start} className="answer">
            <b>Начать</b>
          </button>
        </div>
      </div>
    </div>
  );
}
