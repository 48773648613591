import React, { Component } from 'react'
import Module from '../Components/Module'
const axios = require('axios')
export default class Modules extends Component {
    constructor(props){
        super(props)
        this.state = {
            modules: []
        }
    }
    componentDidMount(){
        this.getModules()
    }
    createModule = async (e) => {
        e.preventDefault();
        await axios.post('https://www.lipids.scardio.ru/api/modules')
        alert('Успешно')
        this.getModules();
    }
    getModules = async () => {
        let modules = await axios.get('https://www.lipids.scardio.ru/api/modules');
        this.setState({modules: modules.data.modules})
    }
    update = async (e) => {
        e.preventDefault()
        let id = e.target.id
        let ru = document.getElementById('ru_'+id).innerHTML
        let eng = document.getElementById('eng_'+id).innerHTML
        await axios.post('https://www.lipids.scardio.ru/api/modules/update', {ru, eng, id})
        alert('Успешно')
        this.getModules();

    }
    delete = async (e) => {
        e.preventDefault()
        let id = e.target.id
        id = id.substring(1)
        await axios.post('https://www.lipids.scardio.ru/api/modules/delete', {id})
        alert('Модуль удален')
        this.getModules();
    }
    
    render() {
        return (
            <>
            <div className="admin__wrapper" id="admin">
            <div className="admin__container">
                <div className="center">
                <div className="add--group">
                    <h3>Модули / </h3>
                    <button className="group__button" onClick={this.createModule}>Создать</button>
                </div>
                </div>                
            </div>
            </div>

            <div className="admin__wrapper" id="admin">
            <div className="admin__container">
                <div className="center">
                    {this.state.modules.map(object => {
                        return <Module key={object.id} id={object.id} ru={object.ru} eng={object.eng} delete={this.delete} update={this.update}/>
                    })}
                </div>
                </div>
            </div>
           
            </>
        )
    }
}





   

