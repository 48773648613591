import React from 'react'

export default function Language(props) {

    const setRussian = (e) => {
        e.preventDefault();
        console.log('russian')
        sessionStorage.setItem('lang', 'ru')
        props.setstate('welcome')
    }

    const setEnglish = (e) => {
        e.preventDefault();
        console.log('english')
        sessionStorage.setItem('lang', 'eng')
        props.setstate('welcome')
    }
    
    return (
        <div className="container">
            <div className="card">
                <img src="/RKO.png" alt='logo'></img>
                <h3 className='cardQuestion'>Please select a language<br/>Пожалуйста выберите язык</h3>


                <div className="answers">
                    <button className="answer flag_large" onClick={setRussian}>🇷🇺</button>
                    <button className="answer flag_large" onClick={setEnglish}>🇬🇧</button>
                </div>
            </div>
        </div>
    )
}
